@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.card-section {
  max-width: unset !important;
  flex-wrap: wrap;
  display: flex;
}

.card-group {
  display: flex;
  flex-wrap: wrap;
  width: calc(50% - 24px);
}

.card {
  border-radius: 20px;
  border: 1px solid #D5D2C5;
  box-shadow: 0px 0px 10px 2px rgb(175 182 170 / 40%);
  margin: 0 22px 22px 0;
  color: $dark-color;
  width: calc(50% - 24px);

  &.card--large {
    width: calc(50% - 24px);
  }

  &.card--dark-green {
    background: $dark-color;
    color: #FFF;

    h2 {
      color: #FFF;
    }
  }

  &.card--green {
    background: $primary-color;
    color: #FFF;

    h2 {
      color: #FFF;
    }
  }
}

.certifications__img {
  width: 130px;
  height: 130px;
  background: #FFF;
  border-radius: 50%;
  padding: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;

  img {
    width: 100%;
  }
}

.card__feature {
  width: 100%;
}

.card-header {
  margin-top: 0;
}

.card__content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;

  .card--large & {
    height: unset;
    padding: 50px;
    justify-content: unset;
  }

  a {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    .card--large & {
      justify-content: end;
    }

    img {
      background: $primary-color;
      padding: 10px;
      border-radius: 50%;
      margin-left: 15px;
      width: 42px;
      box-sizing: border-box;
      transition: background-color 0.2s ease-in-out;

      .card--dark-green &,
      .card--green & {
        background: #FFF;
      }

      .card:hover & {
        background: $dark-color;
      }

      .card--dark-green:hover & {
        background: $primary-color;
      }
    }
  }
}

.about {
  position: relative;
  font-size: 22px;
  color: $dark-color;
  margin-bottom: 200px;
}

.about__image {
  position: absolute;
  left: -40px;
  width: 45%;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 0 30px 30px 0;
  max-width: 900px;
}

.health {
  position: relative;
  font-size: 28px;
  color: $extra-light-color;
  padding: 200px 10%;
  background: $dark-color;
  margin-left: 30%;
  width: 70%;
  border-radius: 40px 0 0 40px;
  box-sizing: border-box;

  :global(.button--light:hover) {
    background-color: $light-color;
    border-color: $light-color;
    color: $primary-color;
  }
}

.health__heading {
  color: $primary-color;
}

.health__image {
  position: absolute;
  right: 0;
  width: 45%;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 0 30px 30px 0;
  max-width: 750px;
}

.callout {
  background: white;
  color: $dark-color;
  box-shadow: 0px 0px 10px 2px rgb(175 182 170 / 40%);
  border-radius: 20px;
  display: flex;
  box-sizing: border-box;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -230%;
  width: 100%;
  max-width: 1500px;
  z-index: 10;
}

.callout__content {
  padding: 60px;
  font-size: 22px;
}

.certifications {
  padding-top: 18%;
  margin-top: 40%;
  color: $dark-color;
  text-align: center;
  background: $light-color;
  background-image: url('../../assets/images/horizontal-decoration-02.png');
  background-repeat: repeat no-repeat;
  background-position: top;
}

.certifications__list {
  margin: auto;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 200px;
    margin-top: 60px;
  }
}

/* Media Queries */
@media (max-width: $breakpoint-xxl) {
  .card-group {
    width: 100%;
  }

  .card {
    &.card--large {
      width: 100%;
    }
  }

  .health {
    margin-left: 10%;
    width: 90%;
  }

  .callout {
    width: 100vw;
    border-radius: 0;
  }
}

@media (max-width: $breakpoint-xl) {
  .callout {
    display: none;
  }
}

@media (max-width: $breakpoint-lg) {
  .health {
    border-radius: 0;
    width: 100%;
    margin-left: 0;

    :global(.halfWidth) {
      width: 100%;
    }
  }

  .health__image {
    display: none;
  }

  .certifications {
    margin-top: 0;
  }
}

@media (max-width: $breakpoint-md) {
  .about {
    :global(.halfWidth) {
      width: 100%;
    }
  }

  .health {
    padding: 50px 15px;
  }

  .about__image {
    position: relative;
    left: unset;
    width: 100%;
    transform: unset;
    top: unset;
    border-radius: 30px;
    margin: 80px auto 0;
    order: -1;
  }

  .card {
    width: 100%;
    margin-right: 0;

    &--large {
      .card__content {
        padding: 30px;

        h2 {
          margin-top: 0;
        }
      }
    }
  }
}
